<template>
    <div class="select-sticker-wrapper">
        <a-modal
            :visible="show"
            title="选择贴纸模板"
            on-ok="handleSubmit"
            @cancel="handleCancel"
        >
            <template slot="footer">
                <a-button :loading="confirmLoading" @click="handleSubmit"
                    >确定</a-button
                >
                <a-button key="back" @click="handleCancel">关闭</a-button>
            </template>

            <div :style="'height: ' + (scroll.y + 90) + 'px;'">
                <a-table
                    :columns="columns"
                    :data-source="list"
                    :loading="loading"
                    :scroll="scroll"
                    :pagination="{
                        pageSize: pageSize,
                        total: totalSize,
                    }"
                    :row-key="(record) => record.id"
                    :row-selection="{
                        type: 'radio',
                        selectedRowKeys: checkSticker,
                        onChange: onSelectChange,
                    }"
                    @change="changePage"
                >
                    <div slot="action" slot-scope="record">
                        <a
                            class="model-img"
                            v-if="record"
                            :href="$fileUrl + record"
                            target="_blank"
                        >
                            <img class="img" :src="$fileUrl + record" alt="" />
                        </a>
                    </div>
                </a-table>
            </div>
        </a-modal>
    </div>
</template>

<script>
import { getStickerList, downloadSticker } from "network/stickerManage";

import { blobDownload } from "common/blobDownload";

export default {
    name: "SelectSticker", // 选择贴纸模板弹窗
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        checkKeys: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    data() {
        return {
            loading: false,
            confirmLoading: false,

            page: 1,
            pageSize: 10,
            totalSize: 0,

            list: [],

            checkSticker: [],

            columns: [
                {
                    title: "模板名称",
                    dataIndex: "name",
                    key: "name",
                    width: 150,
                },
                {
                    title: "图示",
                    dataIndex: "sticker",
                    key: "sticker",
                    width: 150,
                    scopedSlots: { customRender: "action" },
                },
            ],
            scroll: {
                x: true,
                y: 380,
            },
        };
    },
    created() {
        this._getStickerList(this.page, this.pageSize);
    },
    methods: {
        // ----------------事件处理函数---------------
        // 选择
        onSelectChange(selectedRowKeys) {
            this.checkSticker = selectedRowKeys;
        },

        // 切换分页
        changePage({ current }) {
            this.page = current;

            this._getStickerList(this.page, this.pageSize);
        },

        // 确定
        handleSubmit() {
            let codeIds = this.checkKeys,
                stickerId = this.checkSticker[0];
            this._downloadSticker(codeIds, stickerId);
        },
        // 取消
        handleCancel() {
            this.$emit("update:show", false);
        },

        // ------------------网络请求---------------
        // 获取柜门贴纸模板列表
        _getStickerList(pageNumber, pageSize) {
            this.loading = true;

            getStickerList(pageNumber, pageSize)
                .then((res) => {
                    this.totalSize = res.totalSize;
                    this.page = res.currentSize > 0 ? pageNumber : this.page;

                    const list = res.data;

                    let newList = [];

                    class Sticker {
                        constructor(obj) {
                            for (const key in obj) {
                                this[key] = obj[key];
                            }
                        }
                    }

                    for (let i = 0; i < list.length; i++) {
                        let item = list[i];
                        let sticker = new Sticker({
                            ...item,
                        });

                        for (const key in sticker) {
                            !sticker[key] &&
                                sticker[key] != 0 &&
                                (sticker[key] = "暂无数据");
                        }

                        newList.push(sticker);
                    }

                    this.loading = false;
                    this.list = newList;

                    if (newList.length > 0) {
                        this.checkSticker[0] = newList[0].id;
                    }
                })
                .catch((err) => {
                    this.loading = false;
                });
        },

        // 下载柜门贴纸
        _downloadSticker(codeIds, stickerId) {
            this.confirmLoading = true;

            downloadSticker(codeIds, stickerId)
                .then((res) => {
                    this.confirmLoading = false;

                    // 下载
                    blobDownload(res, "sticker.zip");
                })
                .catch((err) => {
                    this.confirmLoading = false;
                });
        },
    },
};
</script>

<style lang="less" scoped>
.model-img {
    width: 100px;
    height: 100px;
    display: block;
    overflow: hidden;

    .img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}
</style>