<template>
    <div class="sn-search-wrapper">
        <a-form class="search-wrapper"
                layout="inline"
                :form="form"
                @submit="handleSearch">

            <!-- 唯一编码 -->
            <a-form-item label="唯一编码"
                         class="form-item-wrapper"
                         :label-col="formNo.labelCol"
                         :wrapper-col="formNo.wrapperCol">

                <a-input v-decorator="['machineNo', { initialValue: '' }]"
                         placeholder="按唯一编码搜索" />

            </a-form-item>

            <!-- 盒子唯一码 -->
            <a-form-item label="盒子唯一码"
                         class="form-item-wrapper"
                         :label-col="formSn.labelCol"
                         :wrapper-col="formSn.wrapperCol">

                <a-input class="search-input" v-decorator="['machineSn', { initialValue: '' }]"
                         placeholder="按盒子唯一码搜索" />

            </a-form-item>

            <!-- 绑定状态 -->
            <a-form-item label="状态"
                         class="form-item-wrapper status-form"
                         :label-col="formStatus.labelCol"
                         :wrapper-col="formStatus.wrapperCol">

                <a-select v-decorator="['status', { initialValue: '0' }]">

                    <template v-for="item in status">
                        <a-select-option :value="item.value"
                                         :key="item.value">
                            {{ item.name }}
                        </a-select-option>
                    </template>

                </a-select>

            </a-form-item>

            <!-- 查询/重置 -->
            <a-form-item class="form-item-wrapper">
                <a-button class="submit-btn"
                          type="primary"
                          html-type="submit">
                    查询
                </a-button>

                <a-button @click="resetForm">
                    重置
                </a-button>
            </a-form-item>

        </a-form>
    </div>
</template>

<script>
export default {
    name: 'SnSearch', // 唯一编码管理筛选
    data () {
        return {
            formNo: {
                labelCol: { span: 7 },
                wrapperCol: { span: 14 },
            },
            formSn: {
                labelCol: { span: 9 },
                wrapperCol: { span: 14 },
            },
            formStatus: {
                labelCol: { span: 7 },
                wrapperCol: { span: 14 },
            },
            form: this.$form.createForm(this, { name: 'snSearch' }),

            status: [
                {
                    name: '全部',
                    value: '0'
                },
                {
                    name: '未绑定商家',
                    value: '1'
                },
                {
                    name: '已绑定商家',
                    value: '2'
                }
            ]
        }
    },
    methods: {
        // 点击查询
        handleSearch (e) {
            e.preventDefault();
            const values = this.form.getFieldsValue();
            
            this.$emit('onSearch', values)
        },
        // 重置
        resetForm() {
            this.form.resetFields()

            const values = this.form.getFieldsValue();
            
            this.$emit('onSearch', values)
        }
    }
}
</script>

<style lang="less" scoped>
.sn-search-wrapper {
    width: 100%;

    .search-wrapper {
        width: 100%;

        .form-item-wrapper {
            width: 300px;

            .search-input {
                width: 200px;
            }

            .submit-btn {
                margin-right: 20px;
            }
        }
    }
}
</style>