<template>
    <div class="sn-list" ref="listBox">
        <a-table
            :columns="columns"
            :data-source="list"
            :pagination="false"
            :loading="loading"
            :scroll="scroll"
            :row-key="record => record.id"
            :row-selection="{ selectedRowKeys: checkKeys, onChange: onSelectChange }"
        >
            
            <div slot="action" slot-scope="record">
                <a class="qr-code-img" v-if="record" :href="($fileUrl + record)" target="_blank">
                    <img class="qr-code" :src="$fileUrl + record" alt="" />
                </a>
            </div>
            
        </a-table>
    </div>
</template>

<script>
export default {
    name: "SnList", // 唯一编码列表
    props: {
        list: {
            type: Array,
            default() {
                return [];
            },
        },
        checkKeys: {
            type: Array,
            default() {
                return []
            }
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            columns: [
                {
                    title: "炳联码",
                    dataIndex: "no",
                    key: "no",
                    width: 170,
                },
                {
                    title: "盒子唯一码",
                    dataIndex: "sn",
                    key: "sn",
                    width: 170,
                },
                {
                    title: "已绑定商家",
                    dataIndex: "bindStatus",
                    key: "bindStatus",
                    width: 170,
                },
                {
                    title: "二维码图片",
                    dataIndex: "qrCode",
                    key: "qrCode",
                    width: 170,
                    scopedSlots: { customRender: "action" },
                },
                {
                    title: "创建时间",
                    dataIndex: "createTime",
                    key: "createTime"
                }
            ],
            scroll: {
                x: true,
                y: 480,
            },
        };
    },
    methods: {
        // -----------------事件处理函数----------------
        onSelectChange(selectedRowKeys) {
            this.$emit('update:checkKeys', selectedRowKeys)
        },
    },
};
</script>

<style lang="less" scoped>
.sn-list {
    width: 100%;
    // height: 100%;
    overflow: auto;

    .qr-code {
        width: 100px;
        height: 100px;
    }
}
</style>