<template>
    <div class="sn-btns">
        <a-button class="btn"
                  type="primary"
                  :loading="loading"
                  @click="handleDownload">下载二维码</a-button>

        <a-button class="btn"
                  type="primary"
                  :loading="loading"
                  @click="$emit('onShowSticker')">下载柜门贴纸</a-button>
    </div>
</template>

<script>
import { exportZip } from 'common/imageZip'

export default {
    name: "SnBtns", // 唯一编码管理操作按钮
    props: {
        qrCodes: {
            type: Array,
            default () {
                return []
            }
        }
    },
    data () {
        return {
            loading: false
        }
    },
    methods: {
        async handleDownload () {
            if (this.qrCodes.length > 0) {
                this.loading = true;

                exportZip(this.qrCodes, 'qrcode.zip').then(res => {
                    this.loading = false;
                }).catch(err => {
                    this.loading = false;
                })
            } else {
                this.$message.error('请选择需要下载的二维码');
            }
        }
    },
};
</script>

<style lang="less" scoped>
.sn-btns {
    width: 100%;
    margin: 20px auto;

    .btn {
        margin-right: 20px;
    }
}
</style>