var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"select-sticker-wrapper"},[_c('a-modal',{attrs:{"visible":_vm.show,"title":"选择贴纸模板","on-ok":"handleSubmit"},on:{"cancel":_vm.handleCancel}},[_c('template',{slot:"footer"},[_c('a-button',{attrs:{"loading":_vm.confirmLoading},on:{"click":_vm.handleSubmit}},[_vm._v("确定")]),_c('a-button',{key:"back",on:{"click":_vm.handleCancel}},[_vm._v("关闭")])],1),_c('div',{style:('height: ' + (_vm.scroll.y + 90) + 'px;')},[_c('a-table',{attrs:{"columns":_vm.columns,"data-source":_vm.list,"loading":_vm.loading,"scroll":_vm.scroll,"pagination":{
                    pageSize: _vm.pageSize,
                    total: _vm.totalSize,
                },"row-key":function (record) { return record.id; },"row-selection":{
                    type: 'radio',
                    selectedRowKeys: _vm.checkSticker,
                    onChange: _vm.onSelectChange,
                }},on:{"change":_vm.changePage},scopedSlots:_vm._u([{key:"action",fn:function(record){return _c('div',{},[(record)?_c('a',{staticClass:"model-img",attrs:{"href":_vm.$fileUrl + record,"target":"_blank"}},[_c('img',{staticClass:"img",attrs:{"src":_vm.$fileUrl + record,"alt":""}})]):_vm._e()])}}])})],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }