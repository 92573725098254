import JSZip from "jszip";
import { saveAs } from 'file-saver';

/**
 *
 * @param url 图片路径
 * @param ext 图片格式
 * @param callback 结果回调
 */
 async function getUrlBase64(url, ext) {
    return new Promise((resolve, reject) => {
        let canvas = document.createElement('canvas') // 创建canvas DOM元素
        let ctx = canvas.getContext('2d')
        let img = new Image()
        img.crossOrigin = 'Anonymous' // 处理跨域
        img.src = url
        img.onload = () => {
            canvas.width = 500 // 指定画板的高度,自定义
            canvas.height = 500 // 指定画板的宽度，自定义
            ctx.drawImage(img, 0, 0) // 参数可自定义
            let dataURL = canvas.toDataURL('image/' + ext)
            resolve(dataURL) // 回调函数获取Base64编码
            canvas = null
        }
    })
}
async function exportZip(imgs, zipName) {
    const imgList = imgs;
    const proList = [];
    const zip = new JSZip();
    const cache = {};
    
    await imgList.forEach(item => { // 等待所有图片转换完成
        const pro = getUrlBase64(item.url, '.jpg').then(base64 => {
            const fileName = item.name;

            zip.file(fileName, base64.substring(base64.indexOf(',') + 1), {
                base64: true
            })
            cache[fileName] = base64
        })
        proList.push(pro)
    })

    return new Promise((resolve, reject) => [
        Promise.all(proList).then(res => {
            zip.generateAsync({
                type: 'blob'
            }).then(content => { // 生成二进制流
                saveAs(content, zipName) // 利用file-saver保存文件
                resolve()
            }).catch(err => {
                reject()
            })
        })
    ])
}

export {
    getUrlBase64,
    exportZip
}