import api from './api'
import { request } from './request'

// 获取唯一编码列表
function getSnQrCodeList(pageNumber, pageSize, params = {
    binding: 0,
    no: '',
    sn: ''
}) {
    return request({
        url: api.qrCode.list,
        method: 'post',
        data: { pageNumber, pageSize, params }
    })
}

export {
    getSnQrCodeList
}