<template>
    <div id="sn-manage">
        <!-- 顶部面包屑 -->
        <breadcrumb :routes="breadcrumb" />

        <!-- 数据内容 -->
        <div class="sn-data">
            <!-- 筛选 -->
            <sn-search @onSearch="onSearch" />

            <!-- 操作按钮 -->
            <sn-btns :qr-codes="qrCodes" @onShowSticker="onShowSticker" />

            <!-- 列表 -->
            <div class="sn-list-wrapper">
                <sn-list :list="snList"
                         :check-keys.sync='checkKeys'
                         :loading="loading" />
            </div>

            <!-- 分页 -->
            <pagination :page.sync="page"
                        :page-size="pageSize"
                        :total-size="totalSize"
                        @onPageChange="onPageChange"
                        @onSizeChange="onSizeChange" />
        </div>

        <select-sticker :show.sync="showSticker" :check-keys="checkKeys" />
    </div>
</template>

<script>
import SnSearch from "./childComps/SnSearch";
import SnBtns from "./childComps/SnBtns";
import SnList from './childComps/SnList'
import SelectSticker from './childComps/SelectSticker'

import Breadcrumb from "components/breadcrumb/Breadcrumb";
import Pagination from "components/pagination/Pagination";

import { getSnQrCodeList } from "network/snManage";

export default {
    name: "SnManage", // 唯一编码管理
    components: {
        SnSearch,
        SnBtns,
        SnList,
        SelectSticker,
        Breadcrumb,
        Pagination,
    },
    data () {
        return {
            breadcrumb: [
                {
                    path: '',
                    breadcrumbName: "首页",
                },
                {
                    path: "/snManage",
                    breadcrumbName: "唯一编码管理",
                },
            ],

            page: 1,
            pageSize: 10,
            totalSize: 0,
            loading: false,

            machineNo: '', // 设备唯一编码
            machineSn: '', // 盒子唯一码
            status: 0,

            checkKeys: [], // 选择的编码

            showSticker: false, // 是否显示选择贴纸模板弹窗

            snList: [],
        };
    },
    computed: {
        // 二维码列表
        qrCodes() {
            let list = [];

            this.snList.forEach(item => {
                if (this.checkKeys.indexOf(item.id) >= 0) {
                    list.push({
                        name: `${ item.no }.jpg`,
                        url: this.$fileUrl + item.qrCode
                    })
                }
            })

            return list
        }
    },
    created () {
        this._getSnQrCodeList(this.page, this.pageSize);
    },
    methods: {
        // -------------------事件处理函数-------------------
        // 查询
        onSearch (values) {
            this.machineNo = values.machineNo || '';
            this.machineSn = values.machineSn || '';
            this.status = values.status || 0;

            let params = {
                binding: this.status,
                no: this.machineNo,
                sn: this.machineSn,
            }

            this.page = 1;

            this._getSnQrCodeList(this.page, this.pageSize, params);
        },
        // 切换页码
        onPageChange (page) {
            this.page = page;

            let params = {
                binding: this.status,
                no: this.machineNo,
                sn: this.machineSn,
            }

            this._getSnQrCodeList(this.page, this.pageSize, params);
        },

        // 切换一页多少条
        onSizeChange (pageSize) {
            this.pageSize = pageSize;

            let params = {
                binding: this.status,
                no: this.machineNo,
                sn: this.machineSn,
            }

            this._getSnQrCodeList(this.page, this.pageSize, params);
        },

        // 是否显示选择柜门贴纸弹窗
        onShowSticker() {
            if (this.checkKeys.length > 0) {
                this.showSticker = true
            } else {
                this.$message.error('请选择需要下载的二维码');
            }
        },

        // ---------------------网络请求-------------------
        // 获取唯一编码列表
        _getSnQrCodeList (
            pageNumber,
            pageSize,
            params = {
                binding: 0,
                no: "",
                sn: "",
            }
        ) {
            this.loading = true;

            getSnQrCodeList(pageNumber, pageSize, params)
                .then((res) => {
                    this.totalSize = res.totalSize;
                    this.page = res.currentSize > 0 ? pageNumber : this.page;

                    const list = res.data;

                    let newList = [];

                    class Sn {
                        constructor(obj) {
                            for (const key in obj) {
                                this[key] = obj[key];
                            }
                        }
                    }

                    for (let i = 0; i < list.length; i++) {
                        let item = list[i];
                        let sn = new Sn({
                            bindStatus: item.userName ? item.userOperatorName : '未绑定',
                            ...item,
                        });

                        for (const key in sn) {
                            !sn[key] &&
                                sn[key] != 0 &&
                                (sn[key] = "暂无数据");
                        }

                        newList.push(sn);
                    }

                    this.loading = false;
                    this.snList = newList;
                })
                .catch((err) => {
                    this.loading = false;
                });
        },
    },
};
</script>

<style lang="less" scoped>
#sn-manage {
    width: 100%;
    // height: 100%;
    position: relative;
    // overflow: hidden;
    // display: flex;
    // flex-direction: column;

    .sn-data {
        // flex: 1;
        margin: 0 20px 20px;
        padding: 20px;
        background-color: #fff;
        border-radius: 5px;
        // display: flex;
        // flex-direction: column;
        // overflow: auto;

        .sn-list-wrapper {
            // flex: 1;
            width: 100%;
            margin: 20px auto;
            // overflow: hidden;
        }
    }
}
</style>